<template>
  <div class="r-cover overflow-hidden bg-accent-1">
    <div class="r-cover overflow-auto px-5 md:flex justify-center items-center">
      <div class="w-full max-w-4xl mx-auto pb-10 pt-28 md:pt-40">
        <div class="md:-mt-14">
          <div class="pb-8 md:pb-0">
            <h1 class="heading text-accent-4 athelas-font text-center text-8xl md:text-10xl font-light mb-4 leading-none">
              404
            </h1>
            <h1 class="heading-secondary text-accent-4 athelas-font text-center text-2xl md:text-6xl font-light mb-6 leading-none">
              Page not found
            </h1>
            <p class="wysiwyg text-xl text-center text-white font-light">
              Sorry we couldn't find the page you are looking for.
            </p>
            <div class="links mt-16 md:flex justify-center">
              <Button
                class="button1 opacity-0 mb-4 md:mb-0 md:mr-8"
                :button="{
                  button_link: '/',
                  button_label: `Return to homepage`,
                  button_text: `Home`
                }"
              />
              <Button
                class="button2 opacity-0 mb-4 md:mb-0"
                :button="{
                  button_link: '/explore',
                  button_label: `View all sections`,
                  button_text: `Explore for yourself`
                }"
              />
              <Button
                class="button3 opacity-0 md:ml-8"
                :button="{
                  button_link: '/guided-tours',
                  button_label: `View all guided tours`,
                  button_text: `Take a guided tour`
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'

import Button from '@/components/buttons/Button.vue'

export default {
  name: '404',

  components: {
    Button
  },

  mounted() {
    gsap.fromTo('.heading', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.2, duration: 0.6, ease: 'power1.inOut' })
    gsap.fromTo('.heading-secondary', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.4, duration: 0.6, ease: 'power1.inOut' })
    gsap.fromTo('.wysiwyg', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.6, duration: 0.6, ease: 'power1.inOut' })
    gsap.fromTo('.button1', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.8, duration: 0.6, ease: 'power1.inOut' })
    gsap.fromTo('.button2', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 1, duration: 0.6, ease: 'power1.inOut' })
    gsap.fromTo('.button3', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 1.2, duration: 0.6, ease: 'power1.inOut' })
  },

  watch: {
    $route() {
      gsap.fromTo('.heading', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.2, duration: 0.6, ease: 'power1.inOut' })
      gsap.fromTo('.heading-secondary', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.4, duration: 0.6, ease: 'power1.inOut' })
      gsap.fromTo('.wysiwyg', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.6, duration: 0.6, ease: 'power1.inOut' })
      gsap.fromTo('.button1', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 0.8, duration: 0.6, ease: 'power1.inOut' })
      gsap.fromTo('.button2', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 1, duration: 0.6, ease: 'power1.inOut' })
      gsap.fromTo('.button3', { opacity: 0, x: -30 }, { opacity: 1, x: 0, delay: 1.2, duration: 0.6, ease: 'power1.inOut' })
    }
  }
}
</script>
